var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _vm.popupParam.psiPipeGasketId
                  ? _c(
                      "c-card",
                      {
                        staticClass: "cardClassDetailForm revisionLayout",
                        attrs: { title: "LBLREV", topClass: "topcolor-orange" },
                      },
                      [
                        _c("template", { slot: "card-detail" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                            },
                            [
                              _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-1" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "LBLREVNO",
                                        name: "revisionNum",
                                      },
                                      model: {
                                        value: _vm.data.revisionNum,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "revisionNum", $$v)
                                        },
                                        expression: "data.revisionNum",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-2" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "LBLREVPERIOD",
                                        name: "regDtStr",
                                      },
                                      model: {
                                        value: _vm.data.regDtStr,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regDtStr", $$v)
                                        },
                                        expression: "data.regDtStr",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-1" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        editable: false,
                                        label: "LBLREVUSER",
                                        name: "regUserName",
                                      },
                                      model: {
                                        value: _vm.data.regUserName,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "regUserName", $$v)
                                        },
                                        expression: "data.regUserName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-moc", {
                                      attrs: {
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        isSubmit: _vm.saveCallData,
                                        document: _vm.data,
                                        documentId:
                                          _vm.popupParam.psiPipeGasketId,
                                        documentTitle: "pipeName",
                                        mocRelatedTaskCd: "RT00000001",
                                        label: "LBLMOCNO",
                                        name: "sopMocId",
                                      },
                                      on: {
                                        "update:document": function ($event) {
                                          _vm.data = $event
                                        },
                                      },
                                      model: {
                                        value: _vm.data.sopMocId,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.data, "sopMocId", $$v)
                                        },
                                        expression: "data.sopMocId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-4" },
                                  [
                                    _c("c-text", {
                                      attrs: {
                                        required:
                                          _vm.editable && _vm.isRevision,
                                        editable:
                                          _vm.editable && _vm.isRevision,
                                        label: "LBLREVREASON",
                                        name: "revisionContent",
                                      },
                                      model: {
                                        value: _vm.data.revisionContent,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.data,
                                            "revisionContent",
                                            $$v
                                          )
                                        },
                                        expression: "data.revisionContent",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" },
              [
                _c("c-table", {
                  ref: "table",
                  attrs: {
                    title: "LBL0010419",
                    tableId: "table",
                    columnSetting: false,
                    isFullScreen: false,
                    usePaging: false,
                    columns: _vm.grid.columns,
                    data: _vm.grid.data,
                    gridHeight: "315px",
                  },
                  on: { rowClick: _vm.rowClick },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8" },
              [
                _c(
                  "c-card",
                  {
                    staticClass: "cardClassDetailForm",
                    attrs: { title: "LBL0010420" },
                  },
                  [
                    _c(
                      "template",
                      { slot: "card-select" },
                      [
                        _vm.popupParam.psiPipeGasketId
                          ? _c("c-select", {
                              attrs: {
                                editable: !_vm.isRevision,
                                comboItems: _vm.gridrev.data,
                                type: "custom",
                                typetext: "LBL0010565",
                                itemText: "revisionNum",
                                itemValue: "psiPipeGasketId",
                                name: "selectedpsiPipeGasketId",
                                label: "",
                              },
                              on: { input: _vm.rowClickRev },
                              model: {
                                value: _vm.selectedpsiPipeGasketId,
                                callback: function ($$v) {
                                  _vm.selectedpsiPipeGasketId = $$v
                                },
                                expression: "selectedpsiPipeGasketId",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "template",
                      { slot: "card-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c(
                              "q-btn",
                              {
                                staticClass: "custom-btn",
                                attrs: {
                                  icon: "help",
                                  color: "deep-purple-6",
                                  "text-color": "white",
                                  align: "center",
                                  round: "",
                                },
                              },
                              [
                                _c(
                                  "q-tooltip",
                                  {
                                    attrs: {
                                      anchor: "bottom left",
                                      self: "top left",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "tooltipCustomTop" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$label("LBL0010385")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "tooltipCustom" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$message("MSG0010037"))
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$message("MSG0010038"))
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$message("MSG0010039")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.psiPipeGasketId &&
                                    !_vm.isRevision,
                                  expression:
                                    "editable && popupParam.psiPipeGasketId && !isRevision",
                                },
                              ],
                              attrs: {
                                label: "LBL0010421",
                                icon: "restart_alt",
                              },
                              on: { btnClicked: _vm.SetRevision },
                            }),
                            _c("c-btn", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.editable &&
                                    _vm.popupParam.psiPipeGasketId &&
                                    _vm.isRevision,
                                  expression:
                                    "editable && popupParam.psiPipeGasketId && isRevision",
                                },
                              ],
                              attrs: {
                                label: "LBLREVCANCEL",
                                icon: "restart_alt",
                              },
                              on: { btnClicked: _vm.CancelRevision },
                            }),
                            _vm.editable && _vm.popupParam.psiPipeGasketId
                              ? _c("c-btn", {
                                  attrs: { label: "LBL0010422", icon: "add" },
                                  on: { btnClicked: _vm.addData },
                                })
                              : _vm._e(),
                            _vm.editable &&
                            _vm.popupParam.psiPipeGasketId &&
                            _vm.deletable
                              ? _c("c-btn", {
                                  attrs: {
                                    label: "LBL0010423",
                                    icon: "remove",
                                  },
                                  on: { btnClicked: _vm.deleteData },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: {
                                    url: _vm.saveUrl,
                                    isSubmit: _vm.isSave,
                                    param: _vm.data,
                                    mappingType: _vm.mappingType,
                                    label: "LBLSAVE",
                                    icon: "save",
                                  },
                                  on: {
                                    beforeAction: _vm.saveData,
                                    btnCallback: _vm.saveCallback,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("template", { slot: "card-detail" }, [
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable && _vm.pipeTypeCdable,
                              label: "LBL0010413",
                              name: "pipeTypeCd",
                            },
                            model: {
                              value: _vm.data.pipeTypeCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pipeTypeCd", $$v)
                              },
                              expression: "data.pipeTypeCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              label: "LBL0010414",
                              name: "pipeName",
                            },
                            model: {
                              value: _vm.data.pipeName,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pipeName", $$v)
                              },
                              expression: "data.pipeName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-plant", {
                            attrs: {
                              required: true,
                              editable: _vm.editable,
                              type: "edit",
                              name: "plantCd",
                            },
                            model: {
                              value: _vm.data.plantCd,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "plantCd", $$v)
                              },
                              expression: "data.plantCd",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "MPa",
                              editable: _vm.editable,
                              label: "LBL0010424",
                              name: "designPress",
                            },
                            model: {
                              value: _vm.data.designPress,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "designPress", $$v)
                              },
                              expression: "data.designPress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "℃",
                              editable: _vm.editable,
                              label: "LBL0010415",
                              name: "designTemp",
                            },
                            model: {
                              value: _vm.data.designTemp,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "designTemp", $$v)
                              },
                              expression: "data.designTemp",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010416",
                              name: "pipeMaterial",
                            },
                            model: {
                              value: _vm.data.pipeMaterial,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "pipeMaterial", $$v)
                              },
                              expression: "data.pipeMaterial",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010417",
                              name: "gasketMaterial",
                            },
                            model: {
                              value: _vm.data.gasketMaterial,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "gasketMaterial", $$v)
                              },
                              expression: "data.gasketMaterial",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-4" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010417",
                              name: "gasketMaterial",
                            },
                            model: {
                              value: _vm.data.gasketMaterial,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "gasketMaterial", $$v)
                              },
                              expression: "data.gasketMaterial",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              suffix: "%",
                              editable: _vm.editable,
                              label: "LBL0010407",
                              name: "noneDestructPercent",
                            },
                            model: {
                              value: _vm.data.noneDestructPercent,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "noneDestructPercent", $$v)
                              },
                              expression: "data.noneDestructPercent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("c-text", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBL0010406",
                              name: "afterHeatTreatFlag",
                            },
                            model: {
                              value: _vm.data.afterHeatTreatFlag,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "afterHeatTreatFlag", $$v)
                              },
                              expression: "data.afterHeatTreatFlag",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-12" },
                        [
                          _c("c-textarea", {
                            attrs: {
                              editable: _vm.editable,
                              label: "LBLREMARK",
                              name: "remarks",
                            },
                            model: {
                              value: _vm.data.remarks,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "remarks", $$v)
                              },
                              expression: "data.remarks",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  2
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                staticStyle: { "padding-top": "20px !important" },
              },
              [
                _c("c-upload", {
                  attrs: {
                    attachInfo: _vm.attachInfo,
                    editable: _vm.editable,
                    label: "LBLATTACHMENT",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c(
                  "c-table",
                  {
                    ref: "substanceTable",
                    attrs: {
                      title: "LBL0010425",
                      tableId: "substanceTable",
                      columns: _vm.grid2.columns,
                      data: _vm.grid2.data,
                      columnSetting: false,
                      selection: "multiple",
                      rowKey: "psiPipeGasketSubstancesId",
                      filtering: false,
                      usePaging: false,
                      hideBottom: true,
                      gridHeight: "250px",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "customArea",
                        fn: function ({ props, col }) {
                          return [
                            [
                              col.name === "msdslink" && props.row.mttrid != ""
                                ? _c(
                                    "q-chip",
                                    {
                                      attrs: {
                                        color: "blue",
                                        clickable: true,
                                        "text-color": "white",
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return (() =>
                                            _vm.msdslink(props.row)).apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(" MSDS ")]
                                  )
                                : _vm._e(),
                            ],
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "template",
                      { slot: "table-button" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBL0010426", icon: "add" },
                                  on: { btnClicked: _vm.addApiRow },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLADD", icon: "add" },
                                  on: { btnClicked: _vm.addrow },
                                })
                              : _vm._e(),
                            _vm.editable
                              ? _c("c-btn", {
                                  attrs: { label: "LBLREMOVE", icon: "remove" },
                                  on: { btnClicked: _vm.removeRow },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
              ],
              1
            ),
          ]),
          _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }